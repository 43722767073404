import React from "react";
import PaypalExpressBtn from "gatsby-paypal-button";

export default class MyApp extends React.Component {
  render() {
    const onSuccess = payment => {
      this.props.setProcessingPaypal(true);

      let files = this.props.cartDetails.map(pack => {
        return {
          filename: `${pack.name}.${
            pack.loc.includes("zip")
              ? "zip"
              : pack.loc.includes("docx")
              ? "docx"
              : pack.loc.includes("doc")
              ? "doc"
              : pack.loc.includes("pptx")
              ? "pptx"
              : pack.loc.includes("ppt")
              ? "ppt"
              : pack.loc.includes("xlsx")
              ? "xlsx"
              : pack.loc.includes("xls")
              ? "xls"
              : "pdf"
          }`,
          path: `https://www.depthlearning.co.uk${pack.loc}`,
          ...(!pack.loc.includes(".zip") &&
            !pack.loc.includes(".docx") &&
            !pack.loc.includes(".doc") &&
            !pack.loc.includes(".pptx") &&
            !pack.loc.includes(".ppt") &&
            !pack.loc.includes(".xlsx") &&
            !pack.loc.includes(".xls") && { contentType: "application/pdf" }),
        };
      });
      fetch(`https://dl-cms-api.herokuapp.com/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: process.env.GATSBY_STRAPI_USER,
          password: process.env.GATSBY_STRAPI_PASS,
        }),
      })
        .then(res => res.json())
        .then(({ jwt }) => {
          fetch(`https://dl-cms-api.herokuapp.com/sendfiles`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
              files,
              email: payment.email,
              total: this.props.total,
              cartInfo: Object.keys(this.props.cartDetails).map(key => this.props.cartDetails[key]),
            }),
          }).then(res => {
            this.props.updatePaypalSuccess(true);
            this.props.setProcessingPaypal(false);
          });
        });
    };

    const onCancel = data => {
      // The user pressed "cancel" or closed the PayPal popup
      // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    };

    const onError = err => {
      // The main Paypal script could not be loaded or something blocked the script from loading
      // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
      // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    };

    let env = "production"; // you can set this string to 'production'
    let currency = "GBP"; // you can set this string from your props or state
    let total = this.props.total; // this is the total amount (based on currency) to charge
    // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

    const client = {
      sandbox: process.env.GATSBY_TEST_PAYPAL_CLIENT_ID,
      production: process.env.GATSBY_PAYPAL_CLIENT_ID,
    };
    // In order to get production's app-ID, you will have to send your app to Paypal for approval first
    // For your sandbox Client-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App" unless you have already done so):
    //   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
    // Note: IGNORE the Sandbox test AppID - this is ONLY for Adaptive APIs, NOT REST APIs)
    // For production app-ID:
    //   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/

    // NB. You can also have many Paypal express checkout buttons on page, just pass in the correct amount and they will work!
    return <PaypalExpressBtn env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />;
  }
}
