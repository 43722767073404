import React from "react";
import Layout from "../components/Layout";
import CartOverview from "../components/CartOverview";
const Basket = props => {
  return (
    <Layout>
      <CartOverview
        error={props.location.search.split("error=")[1]}
        cardStatus={props.location.search.includes("card") ? props.location.search.split("card=")[1].split("&")[0] : ""}
        sessionid={props.location.search.split("session_id=")[1]}
      />
    </Layout>
  );
};
export default Basket;
