import React, { useEffect, useState } from "react";
import useDeviceDetect from "../utils/detectDevice";

import { useShoppingCart } from "use-shopping-cart";
import Title from "./Title";
import { FaTrash } from "react-icons/fa";
import PaypalButton from "../components/PaypalButton";
import PaypalSuccess from "../pages/order/paypalsuccess";
import OrderSuccess from "../pages/order/success";
import { FaSpinner } from "react-icons/fa";

const buttonStyles = {
  color: "white",
  background: "var(--clr-orange-button)",
  height: "40px",
  width: "150px",
  borderRadius: "var(--radius)",
  cursor: "pointer",
  marginBottom: "10px",
};

const Cart = ({ error, cardStatus, sessionid }) => {
  const [loading, setLoading] = useState(false);
  const [updateBasket, setUpdateBasket] = useState(false);
  const [paypalSuccess, setPaypalSuccess] = useState(false);
  const [processingPaypal, setProcessingPaypal] = useState(false);
  const [previouslyCalled, setPreviouslyCalled] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [confirmEmailAddress, setConfirmEmailAddress] = useState("");
  const [freeCheckout, setFreeCheckout] = useState(false);
  const [emailModal, setEmailModal] = useState(true);
  const [emailError, setEmailError] = useState("");

  const handleEmailAddressChange = e => {
    setEmailAddress(e.target.value);
  };

  const handleConfirmEmailAddressChange = e => {
    setConfirmEmailAddress(e.target.value);
  };

  const handleContinueEmail = () => {
    if (!emailAddress && !confirmEmailAddress) {
      setEmailError("Please enter and confirm email address");
      return;
    }
    if (!emailAddress) {
      setEmailError("Please provide an email address");
      return;
    }
    if (!confirmEmailAddress) {
      setEmailError("Please confirm email address");
      return;
    }

    if (emailAddress !== confirmEmailAddress) {
      setEmailError("Email addresses do not match, please check");
      return;
    }
    setEmailModal(false);
  };

  const handleCheckout = () => {
    if (!emailAddress || !confirmEmailAddress) {
      setEmailModal(true);
      return;
    }
    if (!totalPrice) {
      setLoading(true);
      setFreeCheckout(true);
    } else {
      setLoading(true);
      redirectToCheckout();
    }
  };
  /* Gets the totalPrice and a method for redirecting to stripe */
  const { formattedTotalPrice, redirectToCheckout, cartCount, clearCart, cartDetails, addItem, removeItem, totalPrice } = useShoppingCart();

  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    if (sessionid || cardStatus || error) {
      return;
    }
    let discountedCategories = {};
    Object.keys(cartDetails).map(item => {
      if (cartDetails[item].packagePriceId) {
        if (cartDetails[item].category in discountedCategories) {
          discountedCategories[cartDetails[item].category]["quantity"] += +cartDetails[item].quantity;
          // discountedCategories[cartDetails[item].category]['applied'] = +cartDetails[item].packageQuantity;
        } else {
          discountedCategories[cartDetails[item].category] = { quantity: cartDetails[item].quantity };
        }
        discountedCategories[cartDetails[item].category]["applied"] = 0;
      }
    });

    let discAppliedObject = {};
    Object.keys(cartDetails).map((item, index) => {
      if (!(cartDetails[item].category in discAppliedObject) && cartDetails[item].packagePriceId) {
        discAppliedObject[cartDetails[item].category] =
          discountedCategories[cartDetails[item].category].quantity - (discountedCategories[cartDetails[item].category].quantity % cartDetails[item].packageQuantity);
      }
      if (
        cartDetails[item].category in discountedCategories &&
        +discountedCategories[cartDetails[item].category].quantity >= +cartDetails[item].packageQuantity &&
        discountedCategories[cartDetails[item].category]["applied"] < +discAppliedObject[cartDetails[item].category]
      ) {
        cartDetails[item].price = cartDetails[item].packagePrice;
        cartDetails[item].id = cartDetails[item].packagePriceId;
        // cartDetails[item].value = cartDetails[item].packagePrice * cartDetails[item].quantity;
        // cartDetails[item].formattedValue = `£${(cartDetails[item].value / 100).toFixed(2)}`;
        // discApplied += cartDetails[item].quantity;
        discountedCategories[cartDetails[item].category]["applied"] = discountedCategories[cartDetails[item].category]["applied"] + 1;
      } else {
        cartDetails[item].price = cartDetails[item].standardPrice;
        cartDetails[item].id = cartDetails[item].standardPriceId;
        // cartDetails[item].value = cartDetails[item].standardPrice * cartDetails[item].quantity;
        // cartDetails[item].formattedValue = `£${(cartDetails[item].value / 100).toFixed(2)}`;
        // discApplied += cartDetails[item].quantity;
      }
    });

    let modifiedCartDetails = { ...cartDetails };
    clearCart();
    Object.keys(modifiedCartDetails).map(m => {
      addItem(modifiedCartDetails[m], modifiedCartDetails[m].quantity);
    });
  }, [updateBasket]);

  const updatePaypalSuccess = b => {
    setPaypalSuccess(b);
  };

  if (paypalSuccess) {
    clearCart();
    return <PaypalSuccess />;
  }

  if (cardStatus === "success" || freeCheckout) {
    const cartInfo = { ...cartDetails };
    clearCart();
    return (
      <OrderSuccess
        cartInfo={Object.keys(cartInfo).map(key => cartInfo[key])}
        sessionid={sessionid}
        total={totalPrice / 100}
        previouslyCalled={previouslyCalled}
        setPreviouslyCalled={setPreviouslyCalled}
        email={emailAddress}
        freeCheckout={freeCheckout}
      />
    );
  }

  return (
    <section className="about-page">
      <div className="section-center about-center">
        <article className="about-text">
          <Title title="Basket" />
          {cartCount === 0 ? (
            <div className="center">There are currently no learning packs in you basket.</div>
          ) : (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 className="width-40">Item</h4>
                <h4 className="width-10">{isMobile ? "Qty" : "Quantity"}</h4>
                <h4 className="width-20">{isMobile ? "Unit" : "Unit Price"}</h4>
                <h4 className="width-30">Total</h4>
              </div>
              {Object.keys(cartDetails).map((cartItem, index) => (
                <div key={index}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p className="width-40">{cartDetails[cartItem].name}</p>
                    <p className="width-10">{cartDetails[cartItem].quantity}</p>
                    {/* <p className="width-20">
                      {cartDetails[cartItem].price === cartDetails[cartItem].packagePrice ? <s>{`£${(cartDetails[cartItem].standardPrice / 100).toFixed(2)}   `}</s> : null}£
                      {(cartDetails[cartItem].price / 100).toFixed(2)}
                      </p>
                      <p className="width-20">£{(cartDetails[cartItem].value / 100).toFixed(2)}</p>
                    */}
                    {cartDetails[cartItem].price === cartDetails[cartItem].packagePrice ? (
                      cartDetails[cartItem].value === 0 ? (
                        <p className="width-20">£ FREE</p>
                      ) : (
                        <>
                          <p className="width-20">
                            <s>{`£${(cartDetails[cartItem].standardPrice / 100).toFixed(2)}   `}</s>£{(cartDetails[cartItem].price / 100).toFixed(2)}
                          </p>
                        </>
                      )
                    ) : (
                      <p className="width-20">£{(cartDetails[cartItem].standardPrice / 100).toFixed(2)}</p>
                    )}
                    <p className="width-30">
                      £{cartDetails[cartItem].value === 0 ? " FREE" : (cartDetails[cartItem].value / 100).toFixed(2)}
                      <FaTrash
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          removeItem(cartDetails[cartItem].id);
                          setUpdateBasket(!updateBasket);
                        }}
                      />
                    </p>
                  </div>
                </div>
                // <img
                //   src={cartDetails[cartItem].img.publicURL}
                //   width="280px"
                //   height="200px"
                //   // alt={learningPack.Subject}
                // />
              ))}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: isMobile ? "center" : "flex-end",
                }}
              >
                <h4>Basket Total: {formattedTotalPrice}</h4>
                {/* <p>{formattedTotalPrice}</p> */}
                <h4>Number of items: {cartCount}</h4>
                {/* <p>{cartCount}</p> */}

                <br />
                <label style={{ marginRight: "25px" }} for="email">
                  Receipt and learning packs will be sent to: <strong>{emailAddress} </strong>
                  <button onClick={() => setEmailModal(true)}>Edit</button>
                </label>
                <br />

                <button
                  style={buttonStyles}
                  disabled={loading}
                  onClick={() => {
                    handleCheckout();
                  }}
                >
                  {!totalPrice ? "Complete Order" : loading ? "Loading..." : "Pay By Card"}
                </button>
                <button style={buttonStyles} onClick={clearCart}>
                  Clear cart
                </button>
                {totalPrice > 0 && (
                  <PaypalButton
                    cartDetails={Object.keys(cartDetails).map(key => cartDetails[key])}
                    total={totalPrice / 100}
                    // previouslyCalled={previouslyCalled}
                    // setPreviouslyCalled={setPreviouslyCalled}
                    updatePaypalSuccess={updatePaypalSuccess}
                    setProcessingPaypal={setProcessingPaypal}
                    processingPaypal={processingPaypal}
                    email={emailAddress}
                    setEmailModal={setEmailModal}
                  />
                )}
              </div>
              {emailModal && (
                <div id="email-modal" className="email-modal">
                  <div className="email-modal-content">
                    <span
                      className="close"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        // width: "100%",
                      }}
                      onClick={() => {
                        setEmailError("");
                        setEmailModal(null);
                      }}
                    >
                      &times;
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      Please provide the email address you would like your receipt and resources sent to
                      <br />
                      <br />
                      <label style={{ marginRight: "25px" }} for="email">
                        <strong>Email:</strong>
                      </label>
                      <input value={emailAddress || null} onChange={handleEmailAddressChange} style={{ height: "25px" }} type="text" id="email" name="email" />
                      <br />
                      <label style={{ marginRight: "25px" }} for="confirm-email">
                        <strong>Confirm Email:</strong>
                      </label>
                      <input onChange={handleConfirmEmailAddressChange} style={{ height: "25px" }} type="text" id="confirm-email" name="confirm-email" />
                      <br />
                      <button
                        style={buttonStyles}
                        disabled={loading}
                        onClick={() => {
                          handleContinueEmail();
                          // handleCheckout();
                        }}
                      >
                        Continue
                      </button>
                      {emailError}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </article>
      </div>
      {processingPaypal ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <FaSpinner icon="spinner" className="spinner"></FaSpinner>
          <div style={{ textAlign: "center" }}>Processing your order...</div>
        </div>
      ) : (
        ""
      )}
      {error === "true" ? <div className="bar error">There was an error processing your payment. Please try again.</div> : null}
      {/* </article> */}
    </section>
  );
};

export default Cart;
